<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <div
          class="row"
          v-if="favoriteProperties && favoriteProperties.length > 0">
          <div class="col-12">
            <h1 class="title">
              Your Wishlist
            </h1>
          </div>
        </div>
        <PropertyList @favoriteProperties="onUpdate" />
      </div>
      <contact-our-experts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import PropertyList from '../../components/PropertyList.vue';
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';

export default {
  name: 'UserFavorites',
  components: {
    PropertyList,
    ProfileMenu,
    ContactOurExperts,
  },
  data() {
    return {
      favoriteProperties: null,
    };
  },
  methods: {
    onUpdate(value) {
      this.favoriteProperties = value;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
